import React from 'react';
import PageLayout from 'components/pageLayout';
import AdminImagesBlock from 'components/block-elements/admin-images/admin-images';

const ImagesBlock = (props) => {
  return (
    <>
      <PageLayout>
        <AdminImagesBlock {...props}/>
      </PageLayout>
    </>
  );
};

export default ImagesBlock;
